import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Chart } from 'react-google-charts';
import { Card } from '../../components/organisms';
import { Loader } from '../../components/atoms';
import { ApplicationState } from '../../store';
import * as actions from '../../store/chartCumulativeRevenue/actions';
import { CumulativeRevenueState } from '../../store/chartCumulativeRevenue/types';

interface OwnProps {
	storeId: number;
}

interface StateFromProps {
  cumulativeRevenue: CumulativeRevenueState;
}

interface DispatchFromProps {
  fetchRequest: (storeId: number) => void;
}

type Props = OwnProps & StateFromProps & DispatchFromProps;

class CumulativeRevenueContainer extends Component<Props> {
  componentDidMount() {
    const { storeId, cumulativeRevenue } = this.props;

    if (cumulativeRevenue.loading) {
      this.props.fetchRequest(storeId);
    }
	}

  public render() {
		const { currentMonthData, lastMonthData, loading } = this.props.cumulativeRevenue;

		let chartData = currentMonthData.map((item, i) => [ item.day, parseFloat(lastMonthData[i] ? lastMonthData[i].total : '0'), parseFloat(item.total)]);
		chartData.unshift(['', 'Mês Anterior', 'Mês Atual']);

    return (
			<Card title='Faturamento Acumulado (mês)'>
				{
					loading ?
					<Loader type='dots' /> :
					<Chart
						loader={<Loader type='dots' />}
						height='300px'
						className='chart'
						chartType='AreaChart'
						data={chartData}
						options={{
							animation: {
								duration: 1000,
								easing: 'inAndOut',
								startup: true
							},
							chart: {
							},
							colors: ['#999999', '#2CC7D1'],
							hAxis: {
								textStyle: {
									fontName: 'Montserrat'
								}
							},
							vAxis: {
								textStyle: {
									fontName: 'Montserrat'
								}
							},
							legend: {
								alignment: 'start',
								position: 'top',
								textStyle: {
									color: '#666666',
									fontName: 'Montserrat',
									fontSize: '16px'
								}
							}
						}} />
				}
			</Card>
    );
  }
}

const mapStateToProps = ({ cumulativeRevenue }: ApplicationState, { storeId }: OwnProps) => {
  return {
		cumulativeRevenue,
		storeId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRequest: (storeId: number) => dispatch(actions.fetchRequest(storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CumulativeRevenueContainer);