import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { fetchSuccess, fetchError, FetchRequest } from './actions';
import * as constants from './constants';
import { getProdSalesAndRev } from '../../services/statistics';
import { getISODate } from '../../utils';

function* handleFetch (params: FetchRequest) {
	const { storeId, pageCurrent, pageSize, period, searchTerm } = params.payload;
	const unixPeriod = {
		start: getISODate(period.start, 'start'),
		end: getISODate(period.end, 'end')
	}

	const res = yield call(getProdSalesAndRev, storeId, unixPeriod, pageCurrent, pageSize, searchTerm);

	try {
		if (res.errors) {
      yield put(fetchError(res.errors[0].message));
    } else {
      yield put(fetchSuccess(res.data));
    }
	} catch (err) {
		if (err instanceof Error) {
      console.log(err.stack!);
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError('Um erro ocorreu. Por favor, tente novamente.'));
    }
	}
}

function* watchFetchRequest () {
  yield takeLatest(constants.FETCH_REQUEST, handleFetch);
}

export function* productsSalesAndRevenueSaga () {
  yield all([fork(watchFetchRequest)]);
}

export default productsSalesAndRevenueSaga;
