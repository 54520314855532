import React from 'react';
import './card.scss';

interface Props {
	children: JSX.Element[] | JSX.Element | string;
	modifiers?: string[];
	title?: string;
}

const Card: React.SFC<Props> = ({ children, modifiers, title }: Props) => {
  return (
    <div className={`card ${title ? '' : 'card--headerless'} ${modifiers ? modifiers.map(m => `card--${m}`) : ''}`}>
      {
        title &&
        <div className='card__header'>
          <h3 className='card__title'>{ title }</h3>
        </div>
      }

      <div className='card__body'>
        { children }
      </div>
    </div>
  );
};

export default Card;