import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';

import AppRouter from './routes';
import { store, history } from './store';
import './global.scss';

const receiveMessage = (event: any) => {
	if (event.data.jwt) {
		localStorage.setItem('token', event.data.jwt);
	}
}

window.addEventListener('message', receiveMessage, false);
window.parent.postMessage('jwt','*');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppRouter />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
