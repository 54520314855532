// import { action } from 'typesafe-actions';
import { RevAndSalesByHour } from './types';
import * as constants from './constants';

export interface FetchRequest {
	type: constants.FETCH_REQUEST;
	payload: {
		storeId: number
	}
}

export interface FetchSuccess {
  type: constants.FETCH_SUCCESS;
  payload: RevAndSalesByHour[];
}

export interface FetchError {
  type: constants.FETCH_ERROR;
  payload: string;
}

export type RevAndSalesByHourAction = FetchRequest | FetchSuccess | FetchError;

export const fetchRequest = (storeId: number): FetchRequest => {
  return {
		type: constants.FETCH_REQUEST,
		payload :{
			storeId
		}
  };
};

export const fetchSuccess = (data: RevAndSalesByHour[]): FetchSuccess => {
  return {
    type: constants.FETCH_SUCCESS,
    payload: data
  };
};

export const fetchError = (message: string): FetchError => {
  return {
    type: constants.FETCH_ERROR,
    payload: message
  };
};
