export interface APIError {
  code: number;
  message: string;
}

export interface DefaultResponse {
  error: any;
}

export interface Period {
	end: string;
	start: string;
}

export let TOKEN: string | null | undefined;

if (process.env.NODE_ENV === 'development') {
	TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdG9yZXMiOlsiKiJdfQ.pNLMhCTuBRDzXXcHU01CXqpeU3W7EdQMMfyAa2uIid0';
} else {
	TOKEN = localStorage.getItem('token');
}
