import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { KPI } from '../../components/atoms';
import { ApplicationState } from '../../store';
import * as actions from '../../store/kpi/actions';
import { KPIState } from '../../store/kpi/types';

interface OwnProps {
	storeId: number;
}

interface StateFromProps {
  kpi: KPIState;
}

interface DispatchFromProps {
  fetchRequest: (storeId: number) => void;
}

type Props = OwnProps & StateFromProps & DispatchFromProps;

class KPIContainer extends Component<Props> {
  componentDidMount() {
    const { storeId, kpi } = this.props;

    if (kpi.loadingRevenue && kpi.loadingSales && kpi.loadingPeaks) {
      this.props.fetchRequest(storeId);
    }
  }

  public render() {
    const { kpi } = this.props;
    const currencyOptions = {
      minimumFractionDigits: 2
    };

    return (
      <Fragment>
        <div className='col-md-2 col-6'>
          <KPI
            loading={kpi.loadingRevenue}
            type='currency'
            value={kpi.data.revenue.toLocaleString('pt-BR', currencyOptions)}
            label='Faturamento no dia' />
        </div>

        <div className='col-md-2 col-6'>
          <KPI loading={kpi.loadingSales} type='integer' value={kpi.data.sales} label='Pedidos no dia' />
        </div>

        <div className='col-md-2 col-6'>
          <KPI loading={kpi.loadingPeaks} type='period' value={kpi.data.highestPeak || '0h - 0h'} label='Maior pico de pedidos' />
        </div>

        <div className='col-md-2 col-6'>
          <KPI loading={kpi.loadingPeaks} type='period' value={kpi.data.lowestPeak || '0h - 0h'} label='Menor pico de pedidos' />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ kpi }: ApplicationState, { storeId }: OwnProps) => {
  return {
		kpi,
		storeId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRequest: (storeId: number) => dispatch(actions.fetchRequest(storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KPIContainer);