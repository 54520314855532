import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Chart } from 'react-google-charts';
import { Card } from '../../components/organisms';
import { Loader } from '../../components/atoms';
import { ApplicationState } from '../../store';
import * as actions from '../../store/chartRevAndSalesByHour/actions';
import { RevAndSalesByHourState } from '../../store/chartRevAndSalesByHour/types';

interface OwnProps {
	storeId: number;
}

interface StateFromProps {
  revAndSalesByHour: RevAndSalesByHourState;
}

interface DispatchFromProps {
  fetchRequest: (storeId: number) => void;
}

type Props = OwnProps & StateFromProps & DispatchFromProps;

class RevAndSalesByHourContainer extends Component<Props> {
  componentDidMount() {
    const { storeId, revAndSalesByHour } = this.props;

    if (revAndSalesByHour.loading) {
      this.props.fetchRequest(storeId);
    }
  }

  public render() {
    const { revAndSalesByHour } = this.props;
    const chartData = revAndSalesByHour.data.map(i => [`${i.hour}h`, parseInt(i.sales), parseFloat(i.revenue)]);
    chartData.unshift(['Hora', 'Pedidos', 'Faturamento']);

    return (
      <Card>
				{
					revAndSalesByHour.loading ?
					<Loader type='dots' /> :
					<Chart
						loader={<Loader type='dots' />}
						height='300px'
						className='chart'
						chartType='ColumnChart'
						data={chartData || undefined}
						options={{
							animation: {
								duration: 1000,
								easing: 'inAndOut',
								startup: true
							},
							chartArea: {
								height: '80%',
								width: '90%'
							},
							colors: ['#2B0D61', '#2CC7D1'],
							hAxis: {
								slantedText: true,
								slantedTextAngle: 60,
								textStyle: {
									fontName: 'Montserrat',
									fontSize: '10'
								}
							},
							vAxis: {
								textStyle: {
									fontName: 'Montserrat',
									fontSize: '10'
								}
							},
							series: {
								0: {targetAxisIndex: 0},
								1: {targetAxisIndex: 1}
							},
							vAxes: {
								0: {title: 'Pedidos', fontName: 'Montserrat'},
								1: {title: 'Faturamento', fontName: 'Montserrat'}
							},
							legend: {
								alignment: 'start',
								position: 'top',
								textStyle: {
									color: '#666666',
									fontName: 'Montserrat',
									fontSize: '16px'
								}
							},
							sliceVisibilityThreshold: 0
						}} />
				}
      </Card>
    );
  }
}

const mapStateToProps = ({ revAndSalesByHour }: ApplicationState, { storeId }: OwnProps) => {
  return {
    revAndSalesByHour,
		storeId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRequest: (storeId: number) => dispatch(actions.fetchRequest(storeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RevAndSalesByHourContainer);