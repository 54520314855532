import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { Period } from './types';
import { setPeriodData, setPeriodDataError, setPeriodRevenue, setPeriodRevenueError, setPeriodSales, setPeriodSalesError } from './actions';
import * as constants from './constants';
import { getMatrix, getRevenue, getSales } from '../../services/statistics';
import { getISODate } from '../../utils'

interface Params {
  type: string;
  payload: {
		storeId: number,
    period: Period,
    setPeriod: boolean,
  };
}

function* getRevAndSalesByPeriod (storeId: number, period: Period) {
	const unixPeriod = {
		start: getISODate(period.start, 'start'),
		end: getISODate(period.end, 'end')
	}

  const res = yield call(getMatrix, storeId, unixPeriod, 'day');

	try {
    if (res.error) {
      yield put(setPeriodDataError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
			yield put(setPeriodData(res.data.report));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(setPeriodDataError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(setPeriodDataError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* getRevenueByPeriod (storeId: number, period: Period) {
  const unixPeriod = {
		start: getISODate(period.start, 'start'),
		end: getISODate(period.end, 'end')
	}


  const res = yield call(getRevenue, storeId, unixPeriod);
	try {
    if (res.error) {
      yield put(setPeriodRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
			yield put(setPeriodRevenue(res.data.revenue));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(setPeriodRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(setPeriodRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* getSalesByPeriod (storeId: number, period: Period) {
  const unixPeriod = {
		start: getISODate(period.start, 'start'),
		end: getISODate(period.end, 'end')
	}

  const res = yield call(getSales, storeId, unixPeriod);
	try {
    if (res.error) {
      yield put(setPeriodSalesError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
			yield put(setPeriodSales(res.data.sales));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(setPeriodSalesError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(setPeriodSalesError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* handleFetch (params: Params) {
	const { storeId, period } = params.payload;

  yield fork(getRevAndSalesByPeriod, storeId, period);
  yield fork(getRevenueByPeriod, storeId, period);
  yield fork(getSalesByPeriod, storeId, period);
}

function* watchFetchRequest () {
  yield takeLatest(constants.FETCH_REQUEST, handleFetch);
}

export function* revAndSalesByPeriodSaga () {
  yield all([
		fork(watchFetchRequest)
	]);
}

export default revAndSalesByPeriodSaga;
