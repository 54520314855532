import { Reducer } from 'redux';
import { RevAndSalesByHourState } from './types';
import * as constants from './constants';

const initialState: RevAndSalesByHourState = {
  data: [
		{ hour: '05', sales: '0', revenue: '0' },
		{ hour: '06', sales: '0', revenue: '0' },
		{ hour: '07', sales: '0', revenue: '0' },
		{ hour: '08', sales: '0', revenue: '0' },
		{ hour: '09', sales: '0', revenue: '0' },
		{ hour: '10', sales: '0', revenue: '0' },
		{ hour: '11', sales: '0', revenue: '0' },
		{ hour: '12', sales: '0', revenue: '0' },
		{ hour: '13', sales: '0', revenue: '0' },
		{ hour: '14', sales: '0', revenue: '0' },
		{ hour: '15', sales: '0', revenue: '0' },
		{ hour: '16', sales: '0', revenue: '0' },
		{ hour: '17', sales: '0', revenue: '0' },
		{ hour: '18', sales: '0', revenue: '0' },
		{ hour: '19', sales: '0', revenue: '0' },
		{ hour: '20', sales: '0', revenue: '0' },
		{ hour: '21', sales: '0', revenue: '0' },
		{ hour: '22', sales: '0', revenue: '0' },
		{ hour: '23', sales: '0', revenue: '0' },
		{ hour: '00', sales: '0', revenue: '0' },
		{ hour: '01', sales: '0', revenue: '0' },
		{ hour: '02', sales: '0', revenue: '0' },
		{ hour: '03', sales: '0', revenue: '0' },
		{ hour: '04', sales: '0', revenue: '0' },
		{ hour: '05', sales: '0', revenue: '0' },
	],
  errors: undefined,
  loading: true
};

const reducer: Reducer<RevAndSalesByHourState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case constants.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case constants.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
