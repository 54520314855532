import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory, History } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';

import { kpiSaga, kpiReducer, KPIState } from './kpi';
import { revAndSalesByHourSaga, revAndSalesByHourReducer, RevAndSalesByHourState } from './chartRevAndSalesByHour';
import { revAndSalesByPeriodSaga, revAndSalesByPeriodReducer, RevAndSalesByPeriodState } from './chartRevAndSalesByPeriod';
import { cumulativeRevenueSaga, cumulativeRevenueReducer, CumulativeRevenueState } from './chartCumulativeRevenue';
import { productsSalesAndRevenueSaga, productsSalesAndRevenueReducer, ProductsSalesAndRevenueState } from './tableProdSalesAndRev';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

export interface ApplicationState {
  kpi: KPIState;
  revAndSalesByHour: RevAndSalesByHourState;
  revAndSalesByPeriod: RevAndSalesByPeriodState;
  cumulativeRevenue: CumulativeRevenueState;
  prodSalesAndRev: ProductsSalesAndRevenueState;
  router: RouterState;
}

export const rootReducer = (history: History) => combineReducers<ApplicationState>({
  kpi: kpiReducer,
  revAndSalesByHour: revAndSalesByHourReducer,
  revAndSalesByPeriod: revAndSalesByPeriodReducer,
	cumulativeRevenue: cumulativeRevenueReducer,
	prodSalesAndRev: productsSalesAndRevenueReducer,
  router: connectRouter(history)
});

export function* rootSaga () {
  yield all([
    fork(kpiSaga),
    fork(revAndSalesByHourSaga),
    fork(revAndSalesByPeriodSaga),
    fork(cumulativeRevenueSaga),
    fork(productsSalesAndRevenueSaga),
  ]);
}

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer(history), composeEnhancer(applyMiddleware(routerMiddleware(history), sagaMiddleware)));

sagaMiddleware.run(rootSaga);
