import { Reducer } from 'redux';
import { RevAndSalesByPeriodState } from './types';
import * as constants from './constants';

const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const oneWeekAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 14);
const initialState: RevAndSalesByPeriodState = {
  data: [
		{ day: '01', sales: '0', revenue: '0' },
		{ day: '02', sales: '0', revenue: '0' },
		{ day: '03', sales: '0', revenue: '0' },
		{ day: '04', sales: '0', revenue: '0' },
		{ day: '05', sales: '0', revenue: '0' },
		{ day: '06', sales: '0', revenue: '0' },
		{ day: '07', sales: '0', revenue: '0' },
		{ day: '08', sales: '0', revenue: '0' },
		{ day: '09', sales: '0', revenue: '0' },
		{ day: '10', sales: '0', revenue: '0' },
		{ day: '11', sales: '0', revenue: '0' },
		{ day: '12', sales: '0', revenue: '0' },
		{ day: '13', sales: '0', revenue: '0' },
		{ day: '14', sales: '0', revenue: '0' },
		{ day: '15', sales: '0', revenue: '0' },
	],
  errors: undefined,
  loadingChart: true,
  loadingRevenue: true,
  loadingSales: true,
  period: {
		start: oneWeekAgo,
		end: currentDate,
	},
  periodSet: false,
  revenue: 0,
	sales: 0,
};

const reducer: Reducer<RevAndSalesByPeriodState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case constants.FETCH_REQUEST: {
      const { period, periodSet } = payload;
      return {
        ...state,
        loadingChart: true,
        loadingRevenue: true,
        loadingSales: true,
        period,
        periodSet,
      };
    }
    case constants.SET_PERIOD_DATA: {
      return { ...state, loadingChart: false, data: payload };
    }
    case constants.SET_PERIOD_DATA_ERROR: {
      return { ...state, loadingChart: false, errors: payload };
    }
    case constants.SET_PERIOD_REVENUE: {
      return { ...state, loadingRevenue: false, revenue: payload };
    }
    case constants.SET_PERIOD_REVENUE_ERROR: {
      return { ...state, loadingRevenue: false, errors: payload };
    }
    case constants.SET_PERIOD_SALES: {
      return { ...state, loadingSales: false, sales: payload };
    }
    case constants.SET_PERIOD_SALES_ERROR: {
      return { ...state, loadingSales: false, errors: payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
