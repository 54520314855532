import React from 'react';
import './kpi.scss';

interface Props {
  label: string;
  loading: boolean;
  type: string;
  value: number | string;
}

const KPI: React.SFC<Props> = ({ label = 'Carregando...', loading, type, value }: Props) => {
  return (
    <div className={`kpi kpi--${type} ${loading ? 'kpi--loading' : '' }`}>
      <span className='kpi__value'>
        {
          type === 'currency' &&
          <span className='kpi__value-prefix'>R$ </span>
        }

        {value}
      </span>

      <span className='kpi__label'>
        {label}
      </span>
    </div>
  );
};

export default KPI;