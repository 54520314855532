import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import './datepicker-std.scss';
import './datepicker.scss';

import { getFormattedDate } from '../../../utils';

// import range from 'lodash/range';
// import getYear from 'date-fns/getYear';
// import getMonth from 'date-fns/getMonth';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);

interface Props {
	currEnd: Date;
	currStart: Date;
	modifiers?: string[];
	periodSet: boolean;
	setPeriod: (start: Date, end: Date) => void;
	unsetPeriod: () => void;
}

// const years = range(1990, getYear(new Date()) + 1, 1);
// const months = [
//   'Janeiro',
//   'Fevereiro',
//   'Março',
//   'Abril',
//   'Maio',
//   'Junho',
//   'Julho',
//   'Agosto',
//   'Setembro',
//   'Outubro',
//   'NOvembro',
//   'Dezembro'
// ];

const Datepicker: React.SFC<Props> = ({ currEnd, currStart, modifiers, periodSet, setPeriod, unsetPeriod }: Props) => {
	const [ toggle, setToggle ] = useState(false);
	const [ newStart, setNewStart ] = useState(currStart);
	const [ newEnd, setNewEnd ] = useState(currEnd);
	const [ sameMonth, setSameMonth ] = useState(false);
	const [ selectedStartMonth, setSelectedStartMonth ] = useState(currStart);
	const [ selectedEndMonth, setSelectedEndMonth ] = useState(currEnd);

	return (
		<div
			className={`datepicker ${sameMonth ? 'datepicker--same-month' : ''} ${periodSet ? 'datepicker--period-set' : '' }
				${modifiers ? modifiers.map(m => `datepicker--${m}`) : ''}`}>
			<button
				className={`datepicker__toggle ${toggle ? 'datepicker__toggle--on' : ''}`}
				onClick={() => {
					setToggle(!toggle);

					if (!toggle && !periodSet) {
						setNewStart(currStart);
						setNewEnd(currEnd);
					}
				}}>
				{
					periodSet ?
					`${getFormattedDate(currStart)} - ${getFormattedDate(currEnd)}` :
					'Período'
				}
			</button>

			<button
				className='datepicker__unset'
				onClick={() => {
				setToggle(false);
				unsetPeriod();
			}}>&times;</button>

			<div className={`datepicker__container ${toggle ? 'datepicker__container--show' : ''}`}>
				<DatePicker
					dateFormat="dd//MM/yyyy"
					fixedHeight
					inline
					locale='pt-BR'
					selected={newStart}
					// renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, prevMonthButtonDisabled }) => (
					// 	<div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
					// 		<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} >
					// 			{"<"}
					// 		</button>

					// 		<select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
					// 			{years.map(option => (
					// 				<option key={option} value={option}>
					// 					{option}
					// 				</option>
					// 			))}
					// 		</select>

					// 		<select
					// 			value={months[getMonth(date)]}
					// 			onChange={({ target: { value } }) => changeMonth(getMonth(parseInt(value)))} >
					// 			{
					// 				months.map((option, i) => (
					// 					<option key={option} value={i}>
					// 						{option}
					// 					</option>
					// 				))
					// 			}
					// 		</select>
					// 	</div>
					// )}
					onMonthChange={(date: Date) => {
						setSelectedStartMonth(date);

						if (selectedEndMonth.getMonth() === date.getMonth() && selectedEndMonth.getFullYear() === date.getFullYear()) {
							setSameMonth(true);
						} else {
							setSameMonth(false);
						}
					}}
					onChange={(date: Date) => {
						if (
							date.getDate() >= newEnd.getDate()
							&& date.getMonth() === newEnd.getMonth()
							&& date.getFullYear() === newEnd.getFullYear()
						) {
							return;
						}

						setNewStart(date)
					}} />

				<DatePicker
					dateFormat="dd//MM/yyyy"
					fixedHeight
					inline
					locale='pt-BR'
					selected={newEnd}
					// renderCustomHeader={({ date, changeYear, changeMonth, increaseMonth, nextMonthButtonDisabled }) => (
					// 	<div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
					// 		<select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
					// 			{years.map(option => (
					// 				<option key={option} value={option}>
					// 					{option}
					// 				</option>
					// 			))}
					// 		</select>

					// 		<select
					// 			value={months[getMonth(date)]}
					// 			onChange={({ target: { value } }) => changeMonth(parseInt(value))}
					// 		>
					// 			{months.map(option => (
					// 				<option key={option} value={option}>
					// 					{option}
					// 				</option>
					// 			))}
					// 		</select>

					// 		<button
					// 			onClick={increaseMonth}
					// 			disabled={nextMonthButtonDisabled}
					// 		>
					// 			{">"}
					// 		</button>
					// 	</div>
					// )}
					onMonthChange={(date: Date) => {
						setSelectedEndMonth(date);

						if (selectedStartMonth.getMonth() === date.getMonth() && selectedStartMonth.getFullYear() === date.getFullYear()) {
							setSameMonth(true);
						} else {
							setSameMonth(false);
						}
					}}
					onChange={(date: Date) => {
						if (
							date.getDate() <= newStart.getDate()
							&& date.getMonth() === newStart.getMonth()
							&& date.getFullYear() === newStart.getFullYear()
						) {
							return;
						}

						setNewEnd(date)
					}} />

				<button
					className='datepicker__set'
					onClick={() => {
					setToggle(false);
					setPeriod(newStart, newEnd);
				}}>Aplicar</button>
			</div>
		</div>
	);
}

export default Datepicker;