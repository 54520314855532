import { Reducer } from 'redux';
import { ProductsSalesAndRevenueState } from './types';
import * as constants from './constants';

const initialState: ProductsSalesAndRevenueState = {
  data: [],
  errors: undefined,
	filters: undefined,
  loading: true,
	pageCurrent: 0,
	pageSize: 10,
	pageSizeOptions: [5, 10, 15, 20, 25],
  period: {
		start: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
		end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
	},
	periodSet: false,
	searchTerm: '',
};

const reducer: Reducer<ProductsSalesAndRevenueState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.FETCH_REQUEST: {
      const { pageCurrent, pageSize, period, periodSet, searchTerm } = payload;
      return {
        ...state,
				loading: true,
				pageCurrent,
				pageSize,
        period,
				periodSet,
				searchTerm,
      };
    }
    case constants.FETCH_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case constants.FETCH_ERROR: {
      return { ...state, loading: false, errors: payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
