import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { RevAndSalesByHour } from './types';
import { fetchError, fetchSuccess } from './actions';
import * as constants from './constants';
import { fetchPeaksSuccess, fetchPeaksError } from '../kpi/actions';
import { getMatrix } from '../../services/statistics';
import { getISODate } from '../../utils'

interface Params {
  type: string;
  payload: {
		storeId: number
  };
}

interface Accumulator {
  highestPeak: {
    interval: string;
    sales: number;
  };
  lowestPeak: {
    interval: string;
    sales: number;
  };
}

const initialValue: Accumulator = {
  highestPeak: {
    interval: '0h - 0h',
    sales: 0,
  },
  lowestPeak: {
    interval: '0h - 0h',
    sales: 0,
  },
};

function* fetchRevAndSalesByHour(data: RevAndSalesByHour[]) {
  const peaks = data.reduce(
    (acc: Accumulator, curr: RevAndSalesByHour): Accumulator => {
      const { highestPeak, lowestPeak } = acc;
      const currentSales = parseInt(curr.sales);
      const currentHour = parseInt(curr.hour);

      if (currentSales === 0) {
        return acc;
      }

      if (currentSales < lowestPeak.sales || (acc.lowestPeak.sales === 0 && currentSales > 0)) {
        return {
          ...acc,
          lowestPeak: {
            interval: `${currentHour}h - ${currentHour === 23 ? '00' : currentHour + 1}h`,
            sales: currentSales,
          },
        };
      }

      if (currentSales > highestPeak.sales) {
        return {
          ...acc,
          highestPeak: {
            interval: `${currentHour}h - ${currentHour === 23 ? '00' : currentHour + 1}h`,
            sales: currentSales,
          },
        };
      }

      return acc;
    },
    initialValue,
  );

  yield put(
    fetchPeaksSuccess({
      highestPeak: peaks.highestPeak.interval,
      lowestPeak: peaks.lowestPeak.interval,
    }),
  );
}

function* handleFetch(params: Params) {
	const { storeId } = params.payload;
	const today = new Date();
	const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
	const period = {
		start: getISODate(today),
		end: getISODate(tomorrow)
	}

  const res = yield call(getMatrix, storeId, period);

  try {
		if (res.error) {
			yield put(fetchError('Não foi possível realizar a operação, tente novamente.'));
      yield put(fetchPeaksError('Não foi possível realizar a operação, tente novamente.'));
    } else {
			yield fork(fetchRevAndSalesByHour, res.data.report);
      yield put(fetchSuccess(res.data.report));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchError('Não foi possível realizar a operação, tente novamente.'));
      yield put(fetchPeaksError('Não foi possível realizar a operação, tente novamente.'));
    } else {
      yield put(fetchError('Não foi possível realizar a operação, tente novamente.'));
      yield put(fetchPeaksError('Não foi possível realizar a operação, tente novamente.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeLatest(constants.FETCH_REQUEST, handleFetch);
}

export function* revAndSalesByHourSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default revAndSalesByHourSaga;
