export const FETCH_REQUEST = '@@kpi/FETCH_REQUEST';
export type FETCH_REQUEST = typeof FETCH_REQUEST;

export const FETCH_REVENUE_SUCCESS = '@@kpi/FETCH_REVENUE_SUCCESS';
export type FETCH_REVENUE_SUCCESS = typeof FETCH_REVENUE_SUCCESS;

export const FETCH_REVENUE_ERROR = '@@kpi/FETCH_REVENUE_ERROR';
export type FETCH_REVENUE_ERROR = typeof FETCH_REVENUE_ERROR;

export const FETCH_SALES_SUCCESS = '@@kpi/FETCH_SALES_SUCCESS';
export type FETCH_SALES_SUCCESS = typeof FETCH_SALES_SUCCESS;

export const FETCH_SALES_ERROR = '@@kpi/FETCH_SALES_ERROR';
export type FETCH_SALES_ERROR = typeof FETCH_SALES_ERROR;

export const FETCH_PEAKS_SUCCESS = '@@kpi/FETCH_PEAKS_SUCCESS';
export type FETCH_PEAKS_SUCCESS = typeof FETCH_PEAKS_SUCCESS;

export const FETCH_PEAKS_ERROR = '@@kpi/FETCH_PEAKS_ERROR';
export type FETCH_PEAKS_ERROR = typeof FETCH_PEAKS_ERROR;