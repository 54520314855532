import { ProductsSalesAndRevenue, Period } from './types';
import * as constants from './constants';

export interface FetchRequest {
  type: constants.FETCH_REQUEST;
  payload: {
		storeId: number,
		pageCurrent: number,
		pageSize: number,
    period: Period,
		periodSet: boolean,
		searchTerm: string;
  };
}

export interface FetchSuccess {
  type: constants.FETCH_SUCCESS;
  payload: ProductsSalesAndRevenue[];
}

export interface FetchError {
  type: constants.FETCH_ERROR;
  payload: string;
}

export type ProductsSalesAndRevenueAction = FetchRequest | FetchSuccess | FetchError;

export const fetchRequest = (storeId: number, pageCurrent: number, pageSize: number, period: Period, periodSet: boolean, searchTerm: string): FetchRequest => {

  return {
    type: constants.FETCH_REQUEST,
    payload: {
			storeId,
			pageCurrent,
			pageSize,
      period,
			periodSet,
			searchTerm
    }
  };
};

export const fetchSuccess = (data: ProductsSalesAndRevenue[]): FetchSuccess => {
  return {
    type: constants.FETCH_SUCCESS,
    payload: data
  };
};

export const fetchError = (message: string): FetchError => {
  return {
    type: constants.FETCH_ERROR,
    payload: message
  };
};
