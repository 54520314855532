export const FETCH_REQUEST = '@@cumulativeRevenue/FETCH_REQUEST';
export type FETCH_REQUEST = typeof FETCH_REQUEST;

export const FETCH_CURRENT_MONTH_SUCCESS = '@@cumulativeRevenue/FETCH_CURRENT_MONTH_SUCCESS';
export type FETCH_CURRENT_MONTH_SUCCESS = typeof FETCH_CURRENT_MONTH_SUCCESS;

export const FETCH_CURRENT_MONTH_ERROR = '@@cumulativeRevenue/FETCH_CURRENT_MONTH_ERROR';
export type FETCH_CURRENT_MONTH_ERROR = typeof FETCH_CURRENT_MONTH_ERROR;

export const FETCH_LAST_MONTH_SUCCESS = '@@cumulativeRevenue/FETCH_LAST_MONTH_SUCCESS';
export type FETCH_LAST_MONTH_SUCCESS = typeof FETCH_LAST_MONTH_SUCCESS;

export const FETCH_LAST_MONTH_ERROR = '@@cumulativeRevenue/FETCH_LAST_MONTH_ERROR';
export type FETCH_LAST_MONTH_ERROR = typeof FETCH_LAST_MONTH_ERROR;