import Axios from 'axios';
import { DefaultResponse, Period, TOKEN } from '../types';

export interface RevenueResponse extends DefaultResponse {
  data: {
    revenue: number;
  };
}

export interface SalesResponse extends DefaultResponse {
  data: {
    sales: number;
  };
}

export interface Report {
  sales: number;
  revenue: number;
  hour: number;
}

export interface MatrixResponse extends DefaultResponse {
  data: {
    report: Report[];
  };
}

export interface CumulativeRevenueResponse extends DefaultResponse {
	data: {
		cumulative: {
			day: string,
			total: number
		}[]
	};
}

export interface ProdSalesAndRevResponse extends DefaultResponse {
	data: {
		id: string,
		description: string,
		quantity: number,
		total: string
	}[];
}

// const API = 'http://172.30.32.79:3002';
const API = 'https://statsapi.goomer.app';
const TIMEZONE = (new Date().getTimezoneOffset() / 60) * -1;

export const getRevenue = async (storeId: number, period: Period): Promise<RevenueResponse> => {
	const { start, end } = period;
	const options = {
		'headers': { 'Authorization': `Bearer ${TOKEN}` },
		'params': { 'utc': TIMEZONE }
	}

  const req = await Axios.get(`${API}/store/${storeId}/orders/revenue/${start}/${end}`, options)
                        .then(({ data, status }): { data: RevenueResponse, status: number} => {
                          if (status >= 200 && status < 300) {
                            return data;
                          }

                          throw new Error('Fetch failed');
                        })
                        .catch(err =>  err);

  return req;
};

export const getSales = async (storeId: number, period: Period): Promise<SalesResponse> => {
	const { start, end } = period;
	const options = {
		'headers': { 'Authorization': `Bearer ${TOKEN}` },
		'params': { 'utc': TIMEZONE }
	}

  const req = await Axios.get(`${API}/store/${storeId}/orders/sales/${start}/${end}`, options)
                        .then(({ data, status }): { data: SalesResponse, status: number} => {
                          if (status >= 200 && status < 300) {
                            return data;
                          }

                          throw new Error('Fetch failed');
                        })
                        .catch(err => err);

  return req;
};

export const getMatrix = async (storeId: number, period: Period, group?: string): Promise<MatrixResponse> => {
	const { start, end } = period;
	const options = {
		'headers': { 'Authorization': `Bearer ${TOKEN}` },
		'params': { 'utc': TIMEZONE, 'group': group }
	}

  const req = await Axios.get(`${API}/store/${storeId}/orders/report/${start}/${end}`, options)
                        .then(({ data, status }): { data: MatrixResponse, status: number} => {
                          if (status >= 200 && status < 300) {
                            return data;
                          }

                          throw new Error('Fetch failed');
                        })
												.catch(err => err);

  return req;
};

export const getProdSalesAndRev = async (storeId: number, period: Period, page: number, pageSize: number, searchTerm?: string) => {
	const { start, end } = period;
	const options = {
		'headers': { 'Authorization': `Bearer ${TOKEN}` },
		'params': { 'utc': TIMEZONE, 'limit': pageSize, 'offset': page * pageSize, description: searchTerm || '' }
	}

	const req = await Axios.get(`${API}/store/${storeId}/sales/products/${start}/${end}`, options)
												.then(({ data, status }): { data: ProdSalesAndRevResponse, status: number} => {
													if (status >= 200 && status < 300) {
														return data;
													}

													throw new Error('Fetch failed');
												})
												.catch(err => err);

  return req;
};

export const getCumulativeRevenue = async (storeId: number, period: Period): Promise<CumulativeRevenueResponse> => {
	const { start, end } = period;
	const options = {
		'headers': { 'Authorization': `Bearer ${TOKEN}` },
		'params': { 'utc': TIMEZONE }
	}

	const req = await Axios.get(`${API}/store/${storeId}/tabs/cumulative-revenue/${start}/${end}`, options)
												.then(({ data, status }): { data: CumulativeRevenueResponse, status: number} => {
													if (status >= 200 && status < 300) {
														return data;
													}

													throw new Error('Fetch failed');
												})
												.catch(err => err);

  return req;
};
