import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import * as constants from './constants';
import { fetchRevenueSuccess, fetchRevenueError, fetchSalesSuccess, fetchSalesError } from './actions';
import { getRevenue, getSales } from '../../services/statistics';
import { fetchRequest as fetchRevAndSalesByHourRequest } from '../chartRevAndSalesByHour/actions';
import { getISODate } from '../../utils'

interface Params {
  type: string;
  payload: {
		storeId: number
  };
}

function* fetchRevAndSalesByHour (storeId: number) {
  yield put(fetchRevAndSalesByHourRequest(storeId));
}

function* fetchRevenueOnDay (storeId: number) {
	const today = new Date();
	const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
	const period = {
		start: getISODate(today),
		end: getISODate(tomorrow)
	}

	const res = yield call(getRevenue, storeId, period);

  try {
    if (res.errors) {
      yield put(fetchRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchRevenueSuccess(res.data.revenue));
    }
  } catch (err) {
    if (err instanceof Error) {
			yield put(fetchRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchRevenueError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* fetchSalesOnDay (storeId: number) {
	const today = new Date();
	const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
	const period = {
		start: getISODate(today),
		end: getISODate(tomorrow)
	}

  const res = yield call(getSales, storeId, period);

  try {
    if (res.errors) {
      yield put(fetchSalesError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchSalesSuccess(res.data.sales));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchSalesError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchSalesError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* handleFetch (params: Params) {
	const { storeId } = params.payload;

  yield fork(fetchRevenueOnDay, storeId);
  yield fork(fetchSalesOnDay, storeId);
  yield fork(fetchRevAndSalesByHour, storeId);
}

function* watchFetchRequest () {
  yield takeEvery(constants.FETCH_REQUEST, handleFetch);
}

export function* kpiSaga () {
  yield all([fork(watchFetchRequest)]);
}

export default kpiSaga;
