import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { fetchCurrentMonthSuccess, fetchCurrentMonthError, fetchLastMonthSuccess, fetchLastMonthError } from './actions';
import * as constants from './constants';
import { getCumulativeRevenue } from '../../services/statistics';
import { getISODate } from '../../utils'

interface Params {
  type: string;
  payload: {
		storeId: number
  };
}

function* fetchLastMonthData (storeId: number) {
	const currentMonth = new Date();
	const firstDayOfLastMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
	const lastDayOfLastMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
	const period = {
		start: getISODate(firstDayOfLastMonth),
		end: getISODate(lastDayOfLastMonth)
	}

	const res = yield call(getCumulativeRevenue, storeId, period);

	try {
    if (res.error) {
      yield put(fetchLastMonthError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchLastMonthSuccess(res.data.cumulative));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchLastMonthError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchLastMonthError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* fetchCurrentMonthData (storeId: number) {
	const currentMonth = new Date();
	const firstDayOfCurrMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
	const lastDayOfCurrMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
	const period = {
		start: getISODate(firstDayOfCurrMonth),
		end: getISODate(lastDayOfCurrMonth)
	}

	const res = yield call(getCumulativeRevenue, storeId, period);

	try {
    if (res.error) {
      yield put(fetchCurrentMonthError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchCurrentMonthSuccess(res.data.cumulative));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchCurrentMonthError('Não foi possível realizar a operação. Tente novamente.'));
    } else {
      yield put(fetchCurrentMonthError('Não foi possível realizar a operação. Tente novamente.'));
    }
  }
}

function* handleFetch(params: Params) {
	const { storeId } = params.payload;

  yield fork(fetchCurrentMonthData, storeId);
  yield fork(fetchLastMonthData, storeId);
}

function* watchFetchRequest() {
  yield takeEvery(constants.FETCH_REQUEST, handleFetch);
}

export function* cumulativeRevenueSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default cumulativeRevenueSaga;
