import { CumulativeRevenue } from './types';
import * as constants from './constants';

export interface FetchRequest {
	type: constants.FETCH_REQUEST;
	payload: {
		storeId: number
	}
}

export interface FetchCurrentMonthSuccess {
  type: constants.FETCH_CURRENT_MONTH_SUCCESS;
  payload: CumulativeRevenue[];
}

export interface FetchCurrentMonthError {
  type: constants.FETCH_CURRENT_MONTH_ERROR;
  payload: string;
}

export interface FetchLastMonthSuccess {
  type: constants.FETCH_LAST_MONTH_SUCCESS;
  payload: CumulativeRevenue[];
}

export interface FetchLastMonthError {
  type: constants.FETCH_LAST_MONTH_ERROR;
  payload: string;
}

export type CumulativeRevenueAction = FetchRequest | FetchCurrentMonthSuccess | FetchCurrentMonthError | FetchLastMonthSuccess | FetchLastMonthError;

export const fetchRequest = (storeId: number): FetchRequest => {
  return {
		type: constants.FETCH_REQUEST,
		payload: {
			storeId
		}
  };
};

export const fetchCurrentMonthSuccess = (data: CumulativeRevenue[]): FetchCurrentMonthSuccess => {
  return {
    type: constants.FETCH_CURRENT_MONTH_SUCCESS,
    payload: data
  };
};

export const fetchCurrentMonthError = (message: string): FetchCurrentMonthError => {
  return {
    type: constants.FETCH_CURRENT_MONTH_ERROR,
    payload: message
  };
};

export const fetchLastMonthSuccess = (data: CumulativeRevenue[]): FetchLastMonthSuccess => {
  return {
    type: constants.FETCH_LAST_MONTH_SUCCESS,
    payload: data
  };
};

export const fetchLastMonthError = (message: string): FetchLastMonthError => {
  return {
    type: constants.FETCH_LAST_MONTH_ERROR,
    payload: message
  };
};
