import { Reducer } from 'redux';
import { KPIState } from './types';
import * as constants from './constants';

const initialState: KPIState = {
  data: {
    revenue: 0,
    sales: 0,
    highestPeak: '0h - 0h',
    lowestPeak: '0h - 0h'
  },
  errors: undefined,
  loadingRevenue: true,
  loadingSales: true,
  loadingPeaks: true
};

const reducer: Reducer<KPIState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // fetch request for Revenue, Sales and Peaks
    case constants.FETCH_REQUEST: {
      return { ...state, loadingRevenue: true, loadingSales: true, loadingPeaks: true };
    }
    // fetch for Revenue successful
    case constants.FETCH_REVENUE_SUCCESS: {
      return { ...state, loadingRevenue: false, data: { ...state.data, revenue: payload } };
    }
    // fetch for Revenue failed
    case constants.FETCH_REVENUE_ERROR: {
      return { ...state, loadingRevenue: false, errors: payload, revenue: 0 };
    }
    // fetch for Sales successful
    case constants.FETCH_SALES_SUCCESS: {
      return { ...state, loadingSales: false, data: { ...state.data, sales: payload } };
    }
    // fetch for Sales failed
    case constants.FETCH_SALES_ERROR: {
      return { ...state, loadingSales: false, errors: payload, sales: 0 };
    }
    // fetch for Peaks successful
    case constants.FETCH_PEAKS_SUCCESS: {
      const { highestPeak, lowestPeak } = payload;
      return { ...state, loadingPeaks: false, data: { ...state.data, highestPeak, lowestPeak } };
    }
    // fetch for Peaks failed
    case constants.FETCH_PEAKS_ERROR: {
      return { ...state, loadingPeaks: false, errors: payload, data: { ...state.data, highestPeak: '0h - 0h', lowestPeak: '0h - 0h' } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
