import React from 'react';
import './totalOnPeriod.scss';
import { Loader } from '../../atoms';

interface Props {
  revenue: string;
  revenueLoading?: boolean;
  sales: string;
  salesLoading?: boolean;
}

const TotalOnPeriod: React.SFC<Props> = ({ revenue, revenueLoading, sales, salesLoading }: Props) => {
  return (
    <div className='total-on-period'>
      <h3 className='total-on-period__title'>Total no Período</h3>

      <div className='total-on-period__total'>
        <span className='total-on-period__total-value'>{revenueLoading ? <Loader type='dots' /> : revenue}</span>
        <span className='total-on-period__total-label'>faturamento</span>
      </div>

      <div className='total-on-period__total'>
        <span className='total-on-period__total-value'>{salesLoading ? <Loader type='dots' /> : sales}</span>
        <span className='total-on-period__total-label'>pedidos</span>
      </div>
    </div>
  );
};

export default TotalOnPeriod;