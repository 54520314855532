import { RevAndSalesByPeriod, Period } from './types';
import * as constants from './constants';

export interface FetchRequest {
  type: constants.FETCH_REQUEST;
  payload: {
		storeId: number,
    period: Period,
    periodSet: boolean
  };
}

export interface SetPeriodData {
  type: constants.SET_PERIOD_DATA;
  payload: RevAndSalesByPeriod[];
}

export interface SetPeriodDataError {
  type: constants.SET_PERIOD_DATA_ERROR;
  payload: string;
}

export interface SetPeriodRevenue {
  type: constants.SET_PERIOD_REVENUE;
  payload: number;
}

export interface SetPeriodRevenueError {
  type: constants.SET_PERIOD_REVENUE_ERROR;
  payload: string;
}

export interface SetPeriodSales {
  type: constants.SET_PERIOD_SALES;
  payload: number;
}

export interface SetPeriodSalesError {
  type: constants.SET_PERIOD_SALES_ERROR;
  payload: string;
}

export type RevAndSalesByPeriodAction =
							FetchRequest | SetPeriodData | SetPeriodDataError |
							SetPeriodRevenue | SetPeriodRevenueError |
							SetPeriodSales | SetPeriodSalesError;

export const fetchRequest = (storeId: number, period: Period, periodSet: boolean): FetchRequest => {
  return {
    type: constants.FETCH_REQUEST,
    payload: {
			storeId,
      period,
      periodSet
    }
  };
};

export const setPeriodData = (data: RevAndSalesByPeriod[]): SetPeriodData => {
  return {
    type: constants.SET_PERIOD_DATA,
    payload: data
  };
};

export const setPeriodDataError = (message: string): SetPeriodDataError => {
  return {
    type: constants.SET_PERIOD_DATA_ERROR,
    payload: message
  };
};

export const setPeriodRevenue = (data: number): SetPeriodRevenue => {
  return {
    type: constants.SET_PERIOD_REVENUE,
    payload: data
  };
};

export const setPeriodRevenueError = (message: string): SetPeriodRevenueError => {
  return {
    type: constants.SET_PERIOD_REVENUE_ERROR,
    payload: message
  };
};

export const setPeriodSales = (data: number): SetPeriodSales => {
  return {
    type: constants.SET_PERIOD_SALES,
    payload: data
  };
};

export const setPeriodSalesError = (message: string): SetPeriodSalesError => {
  return {
    type: constants.SET_PERIOD_SALES_ERROR,
    payload: message
  };
};
