import { HighestAndLowestPeaks } from './types';
import * as constants from './constants';

export interface FetchRequest {
	type: constants.FETCH_REQUEST;
	payload: {
		storeId: number,
	}
}

export interface FetchRevenueSuccess {
  type: constants.FETCH_REVENUE_SUCCESS;
  payload: number;
}

export interface FetchRevenueError {
  type: constants.FETCH_REVENUE_ERROR;
  payload: string;
}

export interface FetchSalesSuccess {
  type: constants.FETCH_SALES_SUCCESS;
  payload: number;
}

export interface FetchSalesError {
  type: constants.FETCH_SALES_ERROR;
  payload: string;
}

export interface FetchPeaksSuccess {
  type: constants.FETCH_PEAKS_SUCCESS;
  payload: HighestAndLowestPeaks;
}

export interface FetchPeaksError {
  type: constants.FETCH_PEAKS_ERROR;
  payload: string;
}

export type KPIAction = FetchRequest | FetchRevenueSuccess | FetchRevenueError |
                        FetchSalesSuccess | FetchSalesError |
                        FetchPeaksSuccess | FetchPeaksError;

export const fetchRequest = (storeId: number): FetchRequest => {
  return {
		type: constants.FETCH_REQUEST,
		payload: {
			storeId
		}
  };
};

export const fetchRevenueSuccess = (data: number): FetchRevenueSuccess => {
  return {
    type: constants.FETCH_REVENUE_SUCCESS,
    payload: data
  };
};

export const fetchRevenueError = (message: string): FetchRevenueError => {
  return {
    type: constants.FETCH_REVENUE_ERROR,
    payload: message
  };
};

export const fetchSalesSuccess = (data: number): FetchSalesSuccess => {
  return {
    type: constants.FETCH_SALES_SUCCESS,
    payload: data
  };
};

export const fetchSalesError = (message: string): FetchSalesError => {
  return {
    type: constants.FETCH_SALES_ERROR,
    payload: message
  };
};

export const fetchPeaksSuccess = (data: HighestAndLowestPeaks): FetchPeaksSuccess => {
  return {
    type: constants.FETCH_PEAKS_SUCCESS,
    payload: data
  };
};

export const fetchPeaksError = (message: string): FetchPeaksError => {
  return {
    type: constants.FETCH_PEAKS_ERROR,
    payload: message
  };
};
