import { Reducer } from 'redux';
import { CumulativeRevenueState } from './types';
import * as constants from './constants';

const initialState: CumulativeRevenueState = {
	currentMonth: 0,
	currentMonthData: [
		{ day: '01', total: '0'},
		{ day: '02', total: '0'},
		{ day: '03', total: '0'},
		{ day: '04', total: '0'},
		{ day: '05', total: '0'},
		{ day: '06', total: '0'},
		{ day: '07', total: '0'},
		{ day: '08', total: '0'},
		{ day: '09', total: '0'},
		{ day: '10', total: '0'},
		{ day: '11', total: '0'},
		{ day: '12', total: '0'},
		{ day: '13', total: '0'},
		{ day: '14', total: '0'},
		{ day: '15', total: '0'},
		{ day: '16', total: '0'},
		{ day: '17', total: '0'},
		{ day: '18', total: '0'},
		{ day: '19', total: '0'},
		{ day: '20', total: '0'},
		{ day: '21', total: '0'},
		{ day: '22', total: '0'},
		{ day: '23', total: '0'},
		{ day: '24', total: '0'},
		{ day: '25', total: '0'},
		{ day: '26', total: '0'},
		{ day: '27', total: '0'},
		{ day: '28', total: '0'},
		{ day: '29', total: '0'},
		{ day: '30', total: '0'},
	],
	lastMonth: 0,
	lastMonthData: [
		{ day: '01', total: '0'},
		{ day: '02', total: '0'},
		{ day: '03', total: '0'},
		{ day: '04', total: '0'},
		{ day: '05', total: '0'},
		{ day: '06', total: '0'},
		{ day: '07', total: '0'},
		{ day: '08', total: '0'},
		{ day: '09', total: '0'},
		{ day: '10', total: '0'},
		{ day: '11', total: '0'},
		{ day: '12', total: '0'},
		{ day: '13', total: '0'},
		{ day: '14', total: '0'},
		{ day: '15', total: '0'},
		{ day: '16', total: '0'},
		{ day: '17', total: '0'},
		{ day: '18', total: '0'},
		{ day: '19', total: '0'},
		{ day: '20', total: '0'},
		{ day: '21', total: '0'},
		{ day: '22', total: '0'},
		{ day: '23', total: '0'},
		{ day: '24', total: '0'},
		{ day: '25', total: '0'},
		{ day: '26', total: '0'},
		{ day: '27', total: '0'},
		{ day: '28', total: '0'},
		{ day: '29', total: '0'},
		{ day: '30', total: '0'},
	],
  loading: true,
  errors: undefined
};

const reducer: Reducer<CumulativeRevenueState> = (state = initialState, action) => {
	const { type, payload } = action;

  switch (type) {
    case constants.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case constants.FETCH_CURRENT_MONTH_SUCCESS: {
      return { ...state, loading: false, currentMonthData: payload };
    }
    case constants.FETCH_CURRENT_MONTH_ERROR: {
      return { ...state, loading: false, errors: payload };
    }
    case constants.FETCH_LAST_MONTH_SUCCESS: {
      return { ...state, loading: false, lastMonthData: payload };
    }
    case constants.FETCH_LAST_MONTH_ERROR: {
      return { ...state, loading: false, errors: payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
