import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faSearch);

import { Dashboard } from './components/templates';

const AppRouter: React.SFC = () => (
  <HashRouter>
    <Switch>
      <Route exact path='/:storeId' component={Dashboard} />
      <Route component={() => <div>Not Found</div>} />
    </Switch>
  </HashRouter>
);

export default AppRouter;
