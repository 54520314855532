import React from 'react';
import { Title } from '../../atoms';

import KPIContainer from '../../../containers/kpi';
import RevAndSalesByHourContainer from '../../../containers/chartRevAndSalesByHour';
import RevAndSalesByPeriodContainer from '../../../containers/chartRevAndSalesByPeriod';
import CumulativeRevenueContainer from '../../../containers/chartCumulativeRevenue';
import ProdSalesAndRevContainer from '../../../containers/tableProdSalesAndRev';

const Dashboard = (props: any) => {
	const { params } = props.match;

  return (
    <main>
      <section className='section'>
        <div className='container-fluid'>
          <Title level='h2' title='Visão geral do dia' />

          <div className='row'>
            <KPIContainer storeId={params.storeId} />
          </div>

          <div className='row'>
            <div className='col-md-8'>
              <RevAndSalesByHourContainer storeId={params.storeId} />
            </div>

            <div className='col-md-4'>
              <CumulativeRevenueContainer storeId={params.storeId} />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <Title level='h2' title='Vendas e Faturamento por Período' />

              <RevAndSalesByPeriodContainer storeId={params.storeId} />
            </div>
          </div>

					<div className='row'>
						<div className='col-md-12'>
              <Title level='h2' title='Produtos (quantidade &times; faturamento)' />

							<ProdSalesAndRevContainer storeId={params.storeId} />
						</div>
					</div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
