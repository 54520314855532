import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Card, StatsTable } from '../../components/organisms';
import { Datepicker } from '../../components/molecules';
import { ApplicationState } from '../../store';
import * as actions from '../../store/tableProdSalesAndRev/actions';
import { ProductsSalesAndRevenueState, Period } from '../../store/tableProdSalesAndRev/types';

interface OwnProps {
	storeId: number;
}

interface StateFromProps {
  prodSalesAndRev: ProductsSalesAndRevenueState;
}

interface DispatchFromProps {
  fetchRequest: (storeId: number, pageCurrent: number, pageSize: number, period: Period, periodSet: boolean, searchTerm: string) => void;
}

type Props = OwnProps & StateFromProps & DispatchFromProps;

class ProdSalesAndRevContainer extends Component<Props> {
	constructor (props: Props) {
		super(props);
	}

  componentWillMount() {
		const { storeId, prodSalesAndRev } = this.props;

    if (prodSalesAndRev.loading) {
      this.props.fetchRequest(storeId, prodSalesAndRev.pageCurrent, prodSalesAndRev.pageSize, prodSalesAndRev.period, prodSalesAndRev.periodSet, prodSalesAndRev.searchTerm);
    }
	}

	changePage(page: number) {
		const { storeId, prodSalesAndRev } = this.props;

		if (page < 0) {
			return;
		}

		this.props.fetchRequest(storeId, page, prodSalesAndRev.pageSize, prodSalesAndRev.period, prodSalesAndRev.periodSet, prodSalesAndRev.searchTerm);
	}

	changePageSize(pageSize: number) {
		const { storeId, prodSalesAndRev } = this.props;

		this.props.fetchRequest(storeId, prodSalesAndRev.pageCurrent, pageSize, prodSalesAndRev.period, prodSalesAndRev.periodSet, prodSalesAndRev.searchTerm);
	}

	setPeriod (start: Date, end: Date) {
		const { storeId, fetchRequest, prodSalesAndRev } = this.props;
		const period = {
			start,
			end
		}

		fetchRequest(storeId, prodSalesAndRev.pageCurrent, prodSalesAndRev.pageSize, period, true, prodSalesAndRev.searchTerm);
	}

	unsetPeriod () {
		const { storeId, fetchRequest, prodSalesAndRev } = this.props;
		const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
		const period = {
			start: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
			end: today
		};

		fetchRequest(storeId, prodSalesAndRev.pageCurrent, prodSalesAndRev.pageSize, period, false, prodSalesAndRev.searchTerm);
	}

	search(term: string) {
		const { storeId, fetchRequest, prodSalesAndRev } = this.props;

		fetchRequest(storeId, prodSalesAndRev.pageCurrent, prodSalesAndRev.pageSize, prodSalesAndRev.period, prodSalesAndRev.periodSet, term);
	}

  public render() {
		const { data, loading, pageCurrent, pageSize, pageSizeOptions, period, periodSet, searchTerm } = this.props.prodSalesAndRev;

    return (
			<Fragment>
				<div className='row'>
					<div className='col-md-9'>
						<Card modifiers={['height-auto']}>
							<StatsTable
								columns={[
									{ accessor: 'description', label: 'Item', sortable: false, visible: true },
									{ accessor: 'quantity', label: 'Quantidade (vendas)', sortable: false, visible: true },
									{ accessor: 'total', label: 'Faturamento (R$)', sortable: false, visible: true }
								]}
								data={data}
								loading={loading}
								pageCurr={pageCurrent}
								pageSize={pageSize}
								pageSizeOptions={pageSizeOptions}
								searchTerm={searchTerm}
								changePage={this.changePage.bind(this)}
								changePageSize={this.changePageSize.bind(this)}
								search={this.search.bind(this)} />
						</Card>
					</div>

					<div className='col-md-3'>
						<Datepicker
							currStart={new Date(period.start)}
							currEnd={new Date(period.end)}
							modifiers={['centered']}
							periodSet={periodSet}
							setPeriod={this.setPeriod.bind(this)}
							unsetPeriod={this.unsetPeriod.bind(this)} />
					</div>
				</div>
			</Fragment>
    );
  }
}

const mapStateToProps = ({ prodSalesAndRev }: ApplicationState, { storeId }: OwnProps) => {
  return {
    prodSalesAndRev,
		storeId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRequest: (storeId: number, pageCurrent: number, pageSize: number, period: Period, periodSet: boolean, searchTerm: string) => dispatch(actions.fetchRequest(storeId, pageCurrent, pageSize, period, periodSet, searchTerm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProdSalesAndRevContainer);