import { format, setHours, setMinutes, setSeconds } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const currencyFormatter = (value: number, symbol: boolean): string => {
	const options = {
		currency: 'BRL',
		currencyDisplay: 'symbol',
		minimumFractionDigits: 2,
		style: 'currency'
	};

	return value.toLocaleString('pt-BR', symbol ? options : { minimumFractionDigits: 2 });
}

export const decimalsFormatter = (value: number): string => {
	return value.toLocaleString('pt-BR');
}

export const timestampToFormattedDate = (timestamp: number): string => {
	const date = new Date(timestamp);
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	return `${day > 9 ? `${day}` : `0${day}`}/${month > 9 ? `${month}` : `0${month}`}/${year}`;
}

export const getFormattedDate = (date: Date): string => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	return `${day > 9 ? `${day}` : `0${day}`}/${month > 9 ? `${month}` : `0${month}`}/${year}`;
}

export const getISODate = (date: Date | number, period?: string): string => {
	const hours = (period === 'end' ? 23 : 0);
	const minutes = (period === 'end' ? 59 : 0);
	const seconds = (period === 'end' ? 59 : 0);

	return format(setHours(setMinutes(setSeconds(date, seconds), minutes), hours), 'YYYY-MM-dd\'T\'HH:mm:ssxxxxx', { awareOfUnicodeTokens: true, locale: ptBR })
}