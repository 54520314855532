export const FETCH_REQUEST = '@@revAndSalesByPeriod/FETCH_REQUEST';
export type FETCH_REQUEST = typeof FETCH_REQUEST;

export const FETCH_SUCCESS = '@@revAndSalesByPeriod/FETCH_SUCCESS';
export type FETCH_SUCCESS = typeof FETCH_SUCCESS;

export const FETCH_ERROR = '@@revAndSalesByPeriod/FETCH_ERROR';
export type FETCH_ERROR = typeof FETCH_ERROR;

export const SET_PERIOD_DATA = '@@revAndSalesByPeriod/SET_PERIOD_DATA';
export type SET_PERIOD_DATA = typeof SET_PERIOD_DATA;

export const SET_PERIOD_DATA_ERROR = '@@revAndSalesByPeriod/SET_PERIOD_DATA_ERROR';
export type SET_PERIOD_DATA_ERROR = typeof SET_PERIOD_DATA_ERROR;

export const SET_PERIOD_REVENUE = '@@revAndSalesByPeriod/SET_PERIOD_REVENUE';
export type SET_PERIOD_REVENUE = typeof SET_PERIOD_REVENUE;

export const SET_PERIOD_REVENUE_ERROR = '@@revAndSalesByPeriod/SET_PERIOD_REVENUE_ERROR';
export type SET_PERIOD_REVENUE_ERROR = typeof SET_PERIOD_REVENUE_ERROR;

export const SET_PERIOD_SALES = '@@revAndSalesByPeriod/SET_PERIOD_SALES';
export type SET_PERIOD_SALES = typeof SET_PERIOD_SALES;

export const SET_PERIOD_SALES_ERROR = '@@revAndSalesByPeriod/SET_PERIOD_SALES_ERROR';
export type SET_PERIOD_SALES_ERROR = typeof SET_PERIOD_SALES_ERROR;