import React, { Fragment } from 'react';
import './title.scss';

interface Props {
  level: string;
  title: string;
}

const Title: React.SFC<Props> = ({ level, title }: Props) => {
  return (
    <Fragment>
      {
        level === 'hero' &&
        <h1 className='title title--hero'>{ title }</h1>
      }
      {
        level === 'h1' &&
        <h1 className='title title--main'>{ title }</h1>
      }
      {
        level === 'h2' &&
        <h2 className='title title--section'>{ title }</h2>
      }
      {
        level === 'h3' &&
        <h3 className='title title--h3'>{ title }</h3>
      }
      {
        level === 'h4' &&
        <h4 className='title title--h4'>{ title }</h4>
      }
      {
        level === 'h5' &&
        <h5 className='title title--h5'>{ title }</h5>
      }
    </Fragment>
  );
};

export default Title;