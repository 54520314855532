import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './statsTable.scss';

import { Loader } from '../../../components/atoms';
import { currencyFormatter, decimalsFormatter } from '../../../utils';

interface ProductsSalesAndRevenue {
  description: string;
	id: string;
  quantity: number;
  total: string;
}

interface Column {
	accessor: string;
	label: string;
	sortable: boolean;
	visible: boolean;
	sort?: () => void;
}

interface Props {
	columns: Column[];
	data: ProductsSalesAndRevenue[];
	loading: boolean;
	pageCurr: number;
	pageSize: number;
	pageSizeOptions: Array<number>;
	searchTerm: string;
	changePage: (page: number) => void;
	changePageSize: (page:number) => void;
	search: (term: string) => void;
}

const StatsTable: React.SFC<Props> = ({ columns, data, loading, pageCurr, pageSize, pageSizeOptions, searchTerm, changePage, changePageSize, search }: Props) => {
	return (
		<Fragment>
			<div className='stats-table__header'>
				<div className='stats-table__row stats-table__row--borderless'>
					<div className='stats-table__search'>
						<label
							htmlFor='stats-table__search-input'
							className='stats-table__search-label'>Buscar</label>
						<input
							type='text'
							className='stats-table__search-input'
							id='stats-table__search-input'
							placeholder='Buscar'
							value={searchTerm}
							onChange={e => search(e.target.value)} />
						<button
							className='stats-table__search-btn'
							onClick={e => {
								e.preventDefault()

								if (searchTerm.length > 0) {
									search(searchTerm);
								}
							}}>
							<FontAwesomeIcon icon='search'/>
						</button>
					</div>
				</div>
				<div className='stats-table__row'>
					{
						columns.map((column: Column, i: number) =>
							<div className={`stats-table__cell stats-table__cell--header ${column.sortable ? 'stats-table__cell--sortable' : ''} ${!column.visible ? 'stats-table__cell--invisible' : ''}`}
								key={i}>
								{column.label}
							</div>
						)
					}
				</div>
			</div>

			<div className='stats-table__body'>
				{
					loading ?
					<div className='stats-table__row'>
						<div className='stats-table__cell' style={{ height: '100px', lineHeight: '100px', padding: '0', textAlign: 'center' }}>
							<Loader type='dots' modifiers={['centered']} />
						</div>
					</div> :
					(
						data ?
						data.map((item: ProductsSalesAndRevenue, i) =>
							<div className='stats-table__row' key={item.id}>
								<div className='stats-table__cell animated lightSpeedIn' style={{animationDelay: `0.${i > 9 ? (i * 10000) : i}s`}}>
									{item.description}
								</div>
								<div className='stats-table__cell animated lightSpeedIn' style={{animationDelay: `0.${i > 9 ? (i * 10000) : i}s`}}>
									{decimalsFormatter(item.quantity)}
								</div>
								<div className='stats-table__cell animated lightSpeedIn' style={{animationDelay: `0.${i > 9 ? (i * 10000) : i}s`}}>
									{currencyFormatter(parseFloat(item.total), false)}
								</div>
							</div>
						) :
						<div className='stats-table__row'>
							<div className='stats-table__cell' style={{ height: '100px', lineHeight: '100px', padding: '0', textAlign: 'center' }}>
								Nenhum produto encontrado.
							</div>
						</div>
					)
				}
			</div>

			<div className='stats-table__footer'>
				<div className='stats-table__page-size'>
					Exibindo:

					<select
						className={`stats-table__page-size-selector ${!data || loading || ((data && data.length) < pageSize) && (pageCurr === 0) ? 'stats-table__page-size-selector--disabled' : ''}`}
						disabled={!data || loading || ((data && data.length) < pageSize) && (pageCurr === 0)}
						onChange={(e) => changePageSize(parseInt(e.target.value))}
						defaultValue={pageSize.toString()}>
						{
							pageSizeOptions.map((size: number, i: number) => <option value={`${size}`} key={i}>{size}</option>)
						}
					</select>
				</div>

				<div className='stats-table__pagination'>
					<button
						className={`stats-table__pagination-btn ${loading || pageCurr === 0 ? 'stats-table__pagination-btn--disabled' : ''}`}
						onClick={() => {
							if (loading || !data || pageCurr === 0) {
								return;
							}
							changePage(pageCurr - 1)
						}}>
						<FontAwesomeIcon icon='chevron-left' />
					</button>

					<button
						className={`stats-table__pagination-btn ${loading || !data || data && data.length < pageSize ? 'stats-table__pagination-btn--disabled' : ''}`}
						onClick={() => {
							if (loading || !data || data && data.length < pageSize) {
								return;
							}
							changePage(pageCurr + 1)
						}}>
						<FontAwesomeIcon icon='chevron-right' />
					</button>
				</div>
			</div>
		</Fragment>
	);
}

export default StatsTable;