import React, { Fragment } from 'react';
import './loader.scss';

interface Props {
	type: string;
	modifiers?: string[];
}

const Loader: React.SFC<Props> = ({ type = 'default', modifiers }: Props) => {
	return (
		<div className={`loader loader--${type} ${modifiers ? modifiers.map(m => `loader--${m}`) : ''}`}>
			{ type === 'default' && 'Carregando...' }
			{
				type === 'dots' &&
				<Fragment>
					<span></span>
					<span></span>
					<span></span>
				</Fragment>
			}
		</div>
	);
}

export default Loader;