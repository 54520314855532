import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Loader } from '../../components/atoms';
import { Chart } from 'react-google-charts';
import { ApplicationState } from '../../store';
import * as actions from '../../store/chartRevAndSalesByPeriod/actions';
import {
  RevAndSalesByPeriodState,
  Period,
} from '../../store/chartRevAndSalesByPeriod/types';
import { TotalOnPeriod, Datepicker } from '../../components/molecules'; /** 1549665000 */
import { currencyFormatter, decimalsFormatter } from '../../utils';

interface OwnProps {
	storeId: number;
}

interface StateFromProps {
  revAndSalesByPeriod: RevAndSalesByPeriodState;
}

interface DispatchFromProps {
	fetchRequest: (storeId: number, period: Period, periodSet: boolean) => void;
}

type Props = OwnProps & StateFromProps & DispatchFromProps;

class RevAndSalesByPeriodContainer extends Component<Props> {
  componentDidMount() {
    const { revAndSalesByPeriod, storeId } = this.props;

    if (
      revAndSalesByPeriod.loadingChart &&
      revAndSalesByPeriod.loadingRevenue &&
      revAndSalesByPeriod.loadingSales
    ) {
      this.props.fetchRequest(storeId, revAndSalesByPeriod.period, false);
    }
	}

	setPeriod (start: Date, end: Date) {
		const { storeId, fetchRequest } = this.props;
		const period = {
			start,
			end
		}

		fetchRequest(storeId, period, true);
	}

	unsetPeriod () {
		const { storeId, fetchRequest } = this.props;
		const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
		const oneWeekAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 14);
		const period = {
			start: oneWeekAgo,
			end: currentDate,
		};

		fetchRequest(storeId, period, false);
	}

  public render() {
		const { data, period, periodSet, revenue, sales, loadingChart, loadingRevenue, loadingSales } = this.props.revAndSalesByPeriod;
    const chartData = data.map(i => [
      (i.hour ? `${i.hour}h` : i.day),
      parseInt(i.sales),
      parseFloat(i.revenue),
    ]);
    chartData.unshift(['Dia', 'Pedidos', 'Faturamento']);

    return (
      <Fragment>
				<div className='row'>
					<div className='col-md-4'>
						<TotalOnPeriod
							revenue={currencyFormatter(revenue, true)}
							revenueLoading={loadingRevenue}
							sales={decimalsFormatter(sales)}
							salesLoading={loadingSales} />
					</div>

					<div className='col-md-8'>
						<Datepicker
							currStart={new Date(period.start)}
							currEnd={new Date(period.end)}
							periodSet={periodSet}
							setPeriod={this.setPeriod.bind(this)}
							unsetPeriod={this.unsetPeriod.bind(this)} />
					</div>

					<div className='col-md-4'>
					</div>
				</div>

				{
					loadingChart ?
					<Loader type='dots' /> :
					<Chart
						loader={<Loader type='dots' />}
						className='chart'
						chartType='LineChart'
						data={chartData}
						height='500px'
						options={{
							animation: {
								duration: 1000,
								easing: 'inAndOut',
								startup: true,
							},
							backgroundColor: 'transparent',
							chartArea: {
								height: '75%',
								width: '85%',
							},
							colors: ['#2B0D61', '#2CC7D1'],
							lineWidth: 3,
							pointSize: 5,
							hAxis: {
								textStyle: {
									fontName: 'Montserrat',
									fontSize: 12,
								},
							},
							vAxis: {
								textStyle: {
									fontName: 'Montserrat',
									fontSize: 12,
								},
							},
							series: {
								0: {targetAxisIndex: 0},
								1: {targetAxisIndex: 1}
							},
							vAxes: {
								0: {title: 'Pedidos', fontName: 'Montserrat'},
								1: {title: 'Faturamento', fontName: 'Montserrat'}
							},
							legend: {
								alignment: 'start',
								position: 'top',
								textStyle: {
									color: '#666666',
									fontName: 'Montserrat',
									fontSize: '16px',
								},
							},
						}} />
				}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ revAndSalesByPeriod }: ApplicationState, { storeId }: OwnProps) => {
  return {
    revAndSalesByPeriod,
		storeId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRequest: (storeId: number, period: Period, periodSet: boolean) => dispatch(actions.fetchRequest(storeId, period, periodSet))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RevAndSalesByPeriodContainer);
